import '../../style/Card.scss'
import pc from '../../assets/Pc.svg'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const Card = ({
	title,
	text,
	leftButton,
	rightButton,
	firstLink,
	secondLink,
}) => {
	useEffect(() => {
		Aos.init({ duration: 700 })
	}, [])

	return (
		<div className='card' data-aos='fade-up'>
			<div className='card-text'>
				<h1 className='card-text-title'>{title}</h1>
				<p className='card-text-text'>{text}</p>
				<div className='card-text-buttons'>
					<a
						href={firstLink}
						className='card-text-button card-text-button-left'
					>
						{leftButton}
					</a>
					<a
						href={secondLink}
						className='card-text-button card-text-button-right'
					>
						{rightButton}
					</a>
				</div>
			</div>
			<div className='card-img'>
				<img
					src={pc}
					className='card-img-img'
					alt='Build the best website with us'
				/>
			</div>
		</div>
	)
}

export default Card
