import { useState } from 'react'
import '../../style/FAQ.scss'
import arrow from '../../assets/arrow.svg'

const FAQItem = ({ question, answer }) => {
	const [active, setActive] = useState(false)

	return (
		<>
			<div
				className='faq-item-question'
				onClick={() => {
					setActive((prev) => !prev)
				}}
			>
				<p className='faq-item-question-text'>{question}</p>
				<img
					className={`faq-item-question-arrow ${
						active && 'faq-item-question-arrow-active'
					}`}
					src={arrow}
					alt='arrow'
				/>
			</div>
			<div
				className={`faq-item-answer ${
					active && 'faq-item-answer-active'
				}`}
			>
				<p className='faq-item-answer-text'>{answer}</p>
			</div>
		</>
	)
}

export default FAQItem
