import React from 'react'
import Carousel from '../components/Carousel'
import GrinchToken from '../assets/GrinchToken.png'
import '../style/Templates.scss'

const Templates = () => {
	return (
		<div className='templates-wrapper'>
			<div className='container'>
				<div className='templates'>
					<h1 className='templates-title'>Templates</h1>
					<div className='templates-container'>
						<div className='template1'>
							<h1 className='template1-title'>
								Infinite carousel loop
							</h1>
							<div
								className='template1-container'
								style={{
									maxWidth: 1200,
									marginLeft: 'auto',
									marginRight: 'auto',
									marginTop: 64,
								}}
							>
								<Carousel>
									<img
										src={GrinchToken}
										alt='grinch token website'
									/>
									<img
										src={GrinchToken}
										alt='grinch token website'
									/>

									<img
										src={GrinchToken}
										alt='grinch token website'
									/>
								</Carousel>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Templates
