import { Link } from 'react-router-dom'
import '../style/Footer.scss'
import logo from '../assets/Logo.svg'

const Footer = () => {
	return (
		<div className='footer-wrapper'>
			<div className='container'>
				<div className='footer'>
					<div className='footer-logo'>
						<img
							className='footer-logo-img'
							src={logo}
							alt='Adxiom logo'
						/>
						<h2 className='footer-logo-text'>Adxiom</h2>
					</div>
					<div className='footer-links'>
						<a href='#pricing' className='footer-link'>
							Pricing
						</a>
						<a href='#faq' className='footer-link'>
							FAQ
						</a>
						<a href='#team' className='footer-link'>
							Team
						</a>
						<Link to='#projects' className='footer-link'>
							Projects
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
