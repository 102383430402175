import axios from 'axios'
import {
	EMAIL_SEND_REQUEST,
	EMAIL_SEND_SUCCESS,
	EMAIL_SEND_FAIL,
} from '../constants/emailConstants'

export const sendEmailVerification = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: EMAIL_SEND_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(
			'https://api.adxiom.com/api/email/generate-email-token-from-logged-in-user',
			config
		)

		dispatch({
			type: EMAIL_SEND_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: EMAIL_SEND_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
