import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../actions/userActions'
import Eye from '../assets/eye.svg'
import EyeSlash from '../assets/eye-slash.svg'
import AppConfig from '../config.js'
import ReCAPTCHA from 'react-google-recaptcha'
import loginSchema from '../models/loginModel'
import '../style/LogIn.scss'

const LogIn = () => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [showPassword, setShowPassword] = useState(false)
	// const [reCaptcha, setReCaptcha] = useState('')
	const [errors, setErrors] = useState('')
	// const [token, setToken] = useState('')

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin

	useEffect(() => {
		if (userInfo) {
			navigate('/')
		}
	})

	const isDisabled = !(email && password) // reCaptcha

	const handleSubmit = async (e) => {
		e.preventDefault()
		try {
			const value = await loginSchema.validateAsync({ email, password })
			if (value) {
				dispatch(login(email, password)) // reCaptcha
			}
		} catch (err) {
			setErrors(err.details[0].message)
		}
	}

	const handleHidePassClick = () => {
		setShowPassword((prevPass) => !prevPass)
	}

	// function onChange(value) {
	// 	setReCaptcha(value)
	// }

	return (
		<div className='login-wrapper'>
			<div className='container'>
				<div className='login'>
					<form onSubmit={handleSubmit} className='login-form'>
						<input
							style={{ marginTop: '0px' }}
							className='login-form-input'
							type='text'
							value={email}
							placeholder='Email'
							onChange={(e) => setEmail(e.target.value)}
						/>
						<div className='login-form-password-container'>
							<input
								className='login-form-input'
								type={showPassword ? 'text' : 'password'}
								value={password}
								placeholder='Password'
								onChange={(e) => setPassword(e.target.value)}
							></input>
							{showPassword ? (
								<img
									src={Eye}
									className='login-form-eye'
									onClick={handleHidePassClick}
								/>
							) : (
								<img
									src={EyeSlash}
									className='login-form-eye'
									onClick={handleHidePassClick}
								/>
							)}
						</div>
						{loading && <p>Loading...</p>}
						{errors && <p style={{ color: 'red' }}>{errors}</p>}
						{error && <p style={{ color: 'red' }}>{error}</p>}
						<button
							className='login-form-button'
							disabled={isDisabled}
						>
							Log In
						</button>
					</form>
					<Link to='/register' className='login-register'>
						I don't have an account.
					</Link>
					{/* <ReCAPTCHA
						className='recaptcha'
						sitekey={AppConfig.GOOGLE.reCaptcha}
						onChange={onChange}
					/> */}
				</div>
			</div>
		</div>
	)
}

export default LogIn
