import {
	NEWSLETTER_SUBSCRIBE_REQUEST,
	NEWSLETTER_SUBSCRIBE_SUCCESS,
	NEWSLETTER_SUBSCRIBE_FAIL,
} from '../constants/newsletterConstants'

export const newsletterSubscriptionReducer = (state = {}, action) => {
	switch (action.type) {
		case NEWSLETTER_SUBSCRIBE_REQUEST:
			return { loading: true }
		case NEWSLETTER_SUBSCRIBE_SUCCESS:
			return {
				loading: false,
				newsletterInfo: action.payload,
			}
		case NEWSLETTER_SUBSCRIBE_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}
