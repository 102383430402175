import '../style/Kinds.scss'

const Kinds = () => {
	return (
		<div className='kinds'>
			<h1 className='kinds-title'>
				What kind of websites do we create ?
			</h1>
			<div className='kinds-items'>
				<div className='kinds-item'>
					{' '}
					<h2 className='kinds-item-title'>Presentation</h2>
					<p className='kinds-item-text'>
						Designed for presenting or previewing your product or
						your company
					</p>
				</div>
				<div className='kinds-item'>
					<h2 className='kinds-item-title'>Ecommerce</h2>
					<p className='kinds-item-text'>
						Used for displaying, adding, deleting, managing and
						selling products.
					</p>
				</div>
				<div className='kinds-item'>
					<h2 className='kinds-item-title'>Business</h2>
					<p className='kinds-item-text'>
						A website dedicated for you custom business with all the
						features needed.
					</p>
				</div>
				<div className='kinds-item'>
					<h2 className='kinds-item-title'>Blog</h2>
					<p className='kinds-item-text'>
						Dedicated to idividuals or companies that want to share
						beliefs or informations
					</p>
				</div>
			</div>
		</div>
	)
}

export default Kinds
