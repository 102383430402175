import React from 'react'
import '../style/AlreadyActivatedAccount.scss'
import { Link } from 'react-router-dom'

const AlreadyActivatedAccount = () => {
	return (
		<div className='already-activated-wrapper'>
			<div className='container'>
				<div className='already-activated'>
					<h1 className='already-activated-title'>
						Your account has already been activated!
					</h1>
					<Link to='/' className='already-activated-home'>
						Home Page
					</Link>
				</div>
			</div>
		</div>
	)
}

export default AlreadyActivatedAccount
