import { useState } from 'react'
import '../style/Pricing.scss'
import check from '../assets/check.svg'
import uncheck from '../assets/uncheck.svg'
import background from '../assets/background.svg'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const Pricing = () => {
	const [active, setActive] = useState(2)
	const pricings = [
		{
			title: 'Presentation',
			pricing: 500,
			description:
				'Designed for presenting or previewing your product or your company',
			perks: [
				{ title: 'Interactive', has: true },
				{ title: 'Eye-catching', has: true },
				{ title: 'Backend', has: false },
				{ title: 'Payments', has: false },
			],
		},
		{
			title: 'Blog',
			pricing: 1000,
			description:
				'Dedicated to idividuals or companies that want to share beliefs or informations',
			perks: [
				{ title: 'Interactive', has: true },
				{ title: 'Eye-catching', has: true },
				{ title: 'Backend', has: true },
				{ title: 'Payments', has: false },
			],
		},
		{
			title: 'Business',
			pricing: 1500,
			description:
				'A website dedicated for your custom business with all the features needed',
			perks: [
				{ title: 'Interactive', has: true },
				{ title: 'Eye-catching', has: true },
				{ title: 'Backend', has: true },
				{ title: 'Payments', has: false },
			],
		},
		{
			title: 'Business',
			pricing: 5000,
			description:
				'Used for displaying, adding, deleting, managing and selling products',
			perks: [
				{ title: 'Interactive', has: true },
				{ title: 'Eye-catching', has: true },
				{ title: 'Backend', has: true },
				{ title: 'Payments', has: true },
			],
		},
	]

	useEffect(() => {
		Aos.init({ duration: 700 })
	}, [])

	const handleChoose = (index) => {
		setActive(index)
	}

	return (
		<div className='pricing-wrapper' id='pricing'>
			<div className='container'>
				<div className='pricing'>
					<h1 className='pricing-title'>Pricing</h1>
					<div className='pricing-container'>
						<img
							src={background}
							className='pricing-container-background1'
							alt='background'
						/>

						<img
							src={background}
							className='pricing-container-background2'
							alt='background'
						/>
						{pricings.map((item, index) => (
							<div
								data-aos='fade-up'
								data-aos-delay={(index * 100).toString()}
								className='pricing-card'
								key={index}
								style={{
									backgroundColor:
										active === index ? '#081C3D' : '',
								}}
							>
								<h2 className='pricing-card-title'>
									{item.title}
								</h2>
								<div className='pricing-card-price-container'>
									<h2 className='pricing-card-price'>
										${item.pricing}
									</h2>
									<p className='pricing-card-price-text'>
										starting
									</p>
								</div>
								<p className='pricing-card-text'>
									{item.description}
								</p>
								<div className='pricing-card-features'>
									{item.perks.map((perk, index) => (
										<div
											className='pricing-card-freature'
											key={index}
										>
											<img
												src={perk.has ? check : uncheck}
												className='pricing-card-feature-img'
												alt='check'
											/>
											<p className='pricing-card-feature-text'>
												{perk.title}
											</p>
										</div>
									))}
								</div>
								<button
									className='pricing-card-button'
									onClick={() => handleChoose(index)}
								>
									Choose Plan
								</button>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Pricing
