import { useState } from 'react'
import { Link, NavLink, useMatch } from 'react-router-dom'
import Logo from '../assets/Logo.svg'
import '../style/Navbar.scss'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions'

const Navbar = () => {
	const [phoneNavActive, setPhoneNavActive] = useState(true)

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const dispatch = useDispatch()

	const logoutHandler = () => {
		dispatch(logout())
	}

	const onMainScreen = useMatch('/')

	return (
		<div className='navbar-wrapper'>
			<div
				className={`navbar-phone ${
					phoneNavActive ? 'navbar-phone-active' : ''
				}`}
			>
				<div className='navbar-phone-link-container'>
					<Link
						className='navbar-phone-link'
						to='/'
						onClick={() => setPhoneNavActive((state) => !state)}
					>
						Home
					</Link>
					{onMainScreen && (
						<>
							<a
								className='navbar-phone-link'
								href='#pricing'
								onClick={() =>
									setPhoneNavActive((state) => !state)
								}
							>
								Pricing
							</a>
							<a
								className='navbar-phone-link'
								href='#faq'
								onClick={() =>
									setPhoneNavActive((state) => !state)
								}
							>
								FAQ
							</a>
							<a
								className='navbar-phone-link'
								href='#team'
								onClick={() =>
									setPhoneNavActive((state) => !state)
								}
							>
								Team
							</a>
							<a
								className='navbar-phone-link'
								href='#projects'
								onClick={() =>
									setPhoneNavActive((state) => !state)
								}
							>
								Projects
							</a>
						</>
					)}
				</div>
				<div className='navbar-phone-acc-container'>
					<div className='navbar-phone-line'></div>
					<Link
						className='navbar-phone-acc'
						to='/register'
						onClick={() => setPhoneNavActive((state) => !state)}
					>
						Register
					</Link>
					<Link
						className='navbar-phone-acc'
						to='/log-in'
						onClick={() => setPhoneNavActive((state) => !state)}
					>
						Log In
					</Link>
				</div>
			</div>
			<div className='container'>
				<div className='navbar'>
					<Link to='/' className='navbar-logo'>
						<img
							src={Logo}
							alt='Adxiom logo Web Design'
							className='navbar-logo-img'
						/>
						<p className='navbar-logo-text'>Adxiom</p>
					</Link>
					{onMainScreen && (
						<div className='navbar-links'>
							<a href='#pricing' className='navbar-link'>
								Pricing
							</a>
							<a href='#faq' className='navbar-link'>
								FAQ
							</a>
							<a href='#team' className='navbar-link'>
								Team
							</a>
							<a href='#projects' className='navbar-link'>
								Projects
							</a>
						</div>
					)}

					<div
						className='navbar-hamburger'
						onClick={() => setPhoneNavActive((state) => !state)}
					>
						<div
							className={`navbar-hamburger-layer ${
								phoneNavActive
									? ''
									: 'navbar-hamburger-layer-top'
							}`}
						></div>
						<div
							className={`navbar-hamburger-layer ${
								phoneNavActive
									? ''
									: 'navbar-hamburger-layer-mid'
							}`}
						></div>
						<div
							className={`navbar-hamburger-layer ${
								phoneNavActive
									? ''
									: 'navbar-hamburger-layer-bot'
							}`}
						></div>
					</div>
					<div className='navbar-account'>
						{userInfo ? (
							<>
								<NavLink
									to='/my-account'
									className='navbar-register'
								>
									{userInfo.first_name}
								</NavLink>
								<button
									className='navbar-login'
									onClick={logoutHandler}
								>
									Log Out
								</button>
							</>
						) : (
							<>
								<NavLink
									to='/register'
									className='navbar-register'
								>
									Register
								</NavLink>
								<NavLink to='/log-in' className='navbar-login'>
									Log In
								</NavLink>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Navbar
