import '../style/Hero.scss'
import Uw from './common/Uw'
import hero from '../assets/hero.svg'
import Instagram from '../assets/instagram.svg'
import TikTok from '../assets/tiktok.svg'
import Discord from '../assets/discord.svg'
import { useDispatch, useSelector } from 'react-redux'

import { newsletterSubscribe } from '../actions/newsletterActions'

const Hero = ({ email, onChange }) => {
	const dispatch = useDispatch()

	const newsletterSubscription = useSelector(
		(state) => state.newsletterSubscription
	)

	const { loading, error, newsletterInfo } = newsletterSubscription

	const handleSubmit = (e) => {
		e.preventDefault()
		dispatch(newsletterSubscribe(email))
	}

	return (
		<div className='hero-wrapper'>
			<div className='container'>
				<div className='hero'>
					<div className='hero-text'>
						<h1 className='hero-text-title'>
							Does your company need an upgrade ?
						</h1>
						<p className='hero-text-p'>
							There is no reason to not start <Uw>today</Uw> your
							website’s upgrade. We have over{' '}
							<Uw>10 developers</Uw> with
							<Uw> 4+ years</Uw> of experience.
						</p>
						<form
							className='hero-text-form'
							onSubmit={handleSubmit}
						>
							<input
								type='text'
								className='hero-text-form-email'
								placeholder='Email address'
								value={email}
								onChange={(e) => onChange(e)}
							/>
							<button
								className='hero-text-form-button'
								style={{ opacity: newsletterInfo && 1 }}
							>
								{newsletterInfo
									? 'Subscribed'
									: loading
										? 'Loading...'
										: 'Subscribe'}
							</button>
						</form>
						{error && (
							<p style={{ color: 'red', marginTop: 10 }}>
								{error}
							</p>
						)}
						<div className='hero-medias'>
							<a
								className='hero-media'
								href='https://www.instagram.com/adxiom/'
							>
								<img
									src={Instagram}
									alt='Link to our Instagram account: adxiom'
									className='hero-media-img'
								/>
								<p className='hero-media-name'>Instagram</p>
							</a>
							<a
								className='hero-media'
								href='https://www.tiktok.com/@adxiom'
							>
								<img
									src={TikTok}
									alt='Link to our TikTok account: adxiom'
									className='hero-media-img'
								/>
								<p className='hero-media-name'>TikTok</p>
							</a>
							<a
								className='hero-media'
								href='https://discord.gg/cuyx4qyRya'
							>
								<img
									src={Discord}
									alt='Link to our Discord server'
									className='hero-media-img'
								/>
								<p className='hero-media-name'>Discord</p>
							</a>
						</div>
					</div>
					<div className='hero-img'>
						<img
							src={hero}
							className='hero-img-svg'
							alt='Creating the best looking website'
						/>
					</div>
				</div>

				<div className='hero-information'>
					<div className='hero-line'></div>
					<div className='hero-information-texts'>
						<div className='hero-information-text'>
							<p className='hero-information-text-up'>
								5<span style={{ color: '#8193B2' }}>+</span>{' '}
								projects
							</p>
							<p className='hero-information-text-down'>
								finished
							</p>
						</div>
						<div className='hero-information-line'></div>
						<div className='hero-information-text'>
							<p className='hero-information-text-up'>
								4<span style={{ color: '#8193B2' }}>+</span>{' '}
								customers
							</p>
							<p className='hero-information-text-down'>served</p>
						</div>
						<div className='hero-information-line'></div>
						<div className='hero-information-text'>
							<p className='hero-information-text-up'>
								99<span style={{ color: '#8193B2' }}>%</span>{' '}
								positive
							</p>
							<p className='hero-information-text-down'>
								feedback
							</p>
						</div>
						<div className='hero-information-line'></div>
						<div className='hero-information-text'>
							<p className='hero-information-text-up'>
								20<span style={{ color: '#8193B2' }}>+</span>{' '}
								thousand
							</p>
							<p className='hero-information-text-down'>
								lines of code
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Hero
