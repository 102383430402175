import '../style/Projects.scss'
// import adxiom from '../assets/adxiom.png'
// import grinchToken from '../assets/GrinchToken.png'
// import andy from '../assets/Andy.png'
// import adxiomVeryOld from '../assets/adxiomVeryOld.png'
import Adxiom from '../assets/Adxiom.png'
import AdblueStore from '../assets/AdblueStore.jpeg'

const Projects = () => {
	return (
		<div className='projects-wrapper' id='projects'>
			<div className='container'>
				<div className='projects'>
					<h1 className='projects-title'>Projects</h1>
					<div className='projects-container'>
						{/* <div className='projects-project'>
							<a
								href='https://adxiom01.vercel.app/'
								className='projects-project-text'
							>
								Adxiom
							</a>
							<img
								className='projects-project-img'
								src={adxiom}
								alt='adxiom website'
							/>
						</div> */}
						{/* <div className='projects-project'>
							<a
								href='https://mygrinchtoken.com'
								className='projects-project-text'
							>
								GrinchToken
							</a>
							<img
								className='projects-project-img'
								src={grinchToken}
								alt='grinch token website'
							/>
						</div> */}
						{/* <div className='projects-project'>
							<a
								href='https://andy-xi.vercel.app'
								className='projects-project-text'
							>
								Andy
							</a>
							<img
								className='projects-project-img'
								src={andy}
								alt='andy popescu website'
							/>
						</div> */}
						{/* <div className='projects-project'>
							<a
								href='https://adxiom.herokuapp.com/'
								className='projects-project-text'
							>
								OldAdxiom - not made for phone
							</a>
							<img
								className='projects-project-img'
								src={adxiomVeryOld}
								alt='adxiom website'
							/>
						</div> */}
						<div className='projects-project'>
							<a
								href='https://adblue-store.ro/'
								className='projects-project-text'
							>
								AdBlue Store
							</a>
							<img
								className='projects-project-img'
								src={AdblueStore}
								alt='adxiom website'
							/>
						</div>
						<div className='projects-project'>
							<a
								href='https://adxiom-medium.vercel.app/'
								className='projects-project-text'
							>
								Old Adxiom
							</a>
							<img
								className='projects-project-img'
								src={Adxiom}
								alt='adxiom website'
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Projects
