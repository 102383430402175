import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { register } from '../actions/userActions.js'
import AppConfig from '../config.js'
// import ReCAPTCHA from 'react-google-recaptcha'
import '../style/Register.scss'

const Register = () => {
	const [first_name, setFirstName] = useState('')
	const [last_name, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [code, setCode] = useState('')
	// const [reCaptcha, setReCaptcha] = useState('')

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin
	const userRegister = useSelector((state) => state.userRegister)
	const { loading: registerLoading, error: registerError } = userRegister

	useEffect(() => {
		if (userInfo) {
			navigate('/')
		}
	})

	const isDisabled = !(
		first_name &&
		last_name &&
		email &&
		password &&
		confirmPassword &&
		code &&
		password === confirmPassword
	)
	//  reCaptcha &&

	const handleSubmit = (e) => {
		e.preventDefault()

		dispatch(
			register(first_name, last_name, email, password, code) // reCaptcha
		)
	}

	// function onChange(value) {
	// 	setReCaptcha(value)
	// }

	return (
		<div className='register-wrapper'>
			<div className='container'>
				<div className='register'>
					<form onSubmit={handleSubmit} className='register-form'>
						<input
							style={{ marginTop: '0px' }}
							className='register-form-input'
							type='text'
							value={first_name}
							placeholder='First Name'
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<input
							className='register-form-input'
							type='text'
							value={last_name}
							placeholder='Last Name'
							onChange={(e) => setLastName(e.target.value)}
						/>
						<input
							className='register-form-input'
							type='text'
							value={email}
							placeholder='Email'
							onChange={(e) => setEmail(e.target.value)}
						/>
						<input
							className='register-form-input'
							type='password'
							value={password}
							placeholder='Password'
							onChange={(e) => setPassword(e.target.value)}
						/>
						<input
							className='register-form-input'
							type='password'
							value={confirmPassword}
							placeholder='Confirm Password'
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
						<input
							className='register-form-input'
							type='text'
							value={code}
							placeholder='Invite Code'
							onChange={(e) => setCode(e.target.value)}
						/>
						{registerLoading && <p>Loading...</p>}
						{registerError && (
							<p style={{ color: 'red' }}>{registerError}</p>
						)}
						<button
							className='register-form-button'
							disabled={isDisabled}
						>
							Register
						</button>
					</form>
					<Link to='/log-in' className='register-login'>
						I already have an account.
					</Link>
					{/* <ReCAPTCHA
						className='recaptcha'
						sitekey={AppConfig.GOOGLE.reCaptcha}
						onChange={onChange}
					/> */}
				</div>
			</div>
		</div>
	)
}

export default Register
