import axios from 'axios'
import {
	NEWSLETTER_SUBSCRIBE_REQUEST,
	NEWSLETTER_SUBSCRIBE_SUCCESS,
	NEWSLETTER_SUBSCRIBE_FAIL,
} from '../constants/newsletterConstants'

export const newsletterSubscribe = (email) => async (dispatch) => {
	try {
		dispatch({ type: NEWSLETTER_SUBSCRIBE_REQUEST })

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.post(
			'https://api.adxiom.com/api/newsletter',
			{
				email: email,
			},
			config
		)

		dispatch({ type: NEWSLETTER_SUBSCRIBE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: NEWSLETTER_SUBSCRIBE_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
