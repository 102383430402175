import '../style/Websites.scss'
import sectionCurve from '../assets/sectionCurve.svg'
import Card from './common/Card'
import Kinds from './Kinds'

const Websites = () => {
	return (
		<div className='websites-wrapper'>
			<img
				src={sectionCurve}
				className='section-curve'
				alt='Section curve'
			/>
			<div className='websites-container'>
				<div className='container'>
					<div className='websites'>
						<Card
							title='Build the best website with us.'
							text='Our developing team would be more than glad to work together
					with you to accomplish the website of your dreams'
							leftButton='Website Demo'
							firstLink='https://adxiom-medium.vercel.app/'
							secondLink='https://discord.gg/cuyx4qyRya'
							rightButton='Contact Us'
						></Card>
						<Kinds></Kinds>
						<Card
							title='Get in touch with our team.'
							text='We can guarantee you that in the moment you will talk to us, you will understand our truthly potential and profesionalism.'
							firstLink='https://discord.gg/cuyx4qyRya'
							secondLink='https://discord.gg/cuyx4qyRya'
							leftButton='Chat with us'
							rightButton='Contact Us'
						></Card>
					</div>
				</div>
			</div>
			<img
				src={sectionCurve}
				className='section-curve-bottom'
				alt='Section curve'
			/>
		</div>
	)
}

export default Websites
