import React, { useEffect } from 'react'
import '../style/AfterActivation.scss'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from '../actions/userActions'

const AfterActivation = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(logout())
	})

	return (
		<div className='after-activation-wrapper'>
			<div className='container'>
				<div className='after-activation'>
					<h1 className='after-activation-title'>
						Your account has been activated!
					</h1>
					<h2 className='after-activation-subtitle'>
						You need to log back in for security reasons:
					</h2>
					<Link
						to='/log-in'
						className='after-activation-login-button'
					>
						Log In Page
					</Link>
				</div>
			</div>
		</div>
	)
}

export default AfterActivation
