import { useDispatch, useSelector } from 'react-redux'
import { listStudents } from '../actions/studentActions'

const Test = () => {
	const dispatch = useDispatch()
	const studentList = useSelector((state) => state.studentList)

	const { loading, error, students } = studentList

	// useEffect(() => {
	// 	dispatch(listStudents())
	// }, [dispatch])

	const handleClick = async () => {
		dispatch(listStudents())
	}

	return (
		<div>
			{loading ? (
				<h2>Loading...</h2>
			) : error ? (
				<h3>{error}</h3>
			) : (
				students.map((student, index) => (
					<h1 key={index}>{student.first_name}</h1>
				))
			)}
			<button onClick={handleClick}>Fetch all students</button>
		</div>
	)
}
//onClick={handleClick}
export default Test
