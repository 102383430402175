import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './pages/Home'
import Test from './pages/Test'
import Register from './pages/Register'
import LogIn from './pages/LogIn'
import NotFound from './pages/NotFound'
import MyAccount from './pages/MyAccount'
import Particles from 'react-tsparticles'
import AlreadyActivatedAccount from './pages/AlreadyActivatedAccount'
import AfterActivation from './pages/AfterActivation'
import Templates from './pages/Templates'

const App = () => {
	const particlesInit = (main) => {
		// console.log(main)
		// you can initialize the tsParticles instance (main) here, adding custom shapes or presets
	}

	const particlesLoaded = (container) => {
		// console.log(container)
	}

	return (
		<BrowserRouter>
			<div
				className='app'
				style={{ minHeight: '100vh', position: 'relative' }}
			>
				<Particles
					id='tsparticles'
					init={particlesInit}
					loaded={particlesLoaded}
					options={{
						fpsLimit: 512,
						particles: {
							number: {
								value: 60,
								density: {
									enable: true,
									value_area: 3077.6758023658635,
								},
							},
							color: {
								value: '#ffffff',
							},
							shape: {
								type: 'circle',
								stroke: {
									width: 0,
									color: '#000000',
								},
								polygon: {
									nb_sides: 5,
								},
								image: {
									src: 'img/github.svg',
									width: 100,
									height: 100,
								},
							},
							opacity: {
								value: 0.5,
								random: false,
								anim: {
									enable: false,
									speed: 1,
									opacity_min: 0.1,
									sync: false,
								},
							},
							size: {
								value: 3,
								random: true,
								anim: {
									enable: false,
									speed: 40,
									size_min: 0.1,
									sync: false,
								},
							},
							line_linked: {
								enable: true,
								distance: 150,
								color: '#ffffff',
								opacity: 0.4,
								width: 1,
							},
							move: {
								enable: true,
								speed: 1,
								direction: 'none',
								random: false,
								straight: false,
								out_mode: 'out',
								bounce: false,
								attract: {
									enable: false,
									rotateX: 600,
									rotateY: 1200,
								},
							},
						},
						interactivity: {
							detect_on: 'canvas',
							events: {
								onhover: {
									enable: false,
									mode: 'repulse',
								},
								onclick: {
									enable: false,
									mode: 'push',
								},
								resize: true,
							},
							modes: {
								grab: {
									distance: 400,
									line_linked: {
										opacity: 1,
									},
								},
								bubble: {
									distance: 400,
									size: 40,
									duration: 2,
									opacity: 8,
									speed: 3,
								},
								repulse: {
									distance: 200,
									duration: 0.4,
								},
								push: {
									particles_nb: 4,
								},
								remove: {
									particles_nb: 2,
								},
							},
						},
						retina_detect: true,
					}}
				/>

				<Navbar />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/test' element={<Test />} />
					<Route path='/register' element={<Register />} />
					<Route path='/log-in' element={<LogIn />} />
					<Route path='/my-account' element={<MyAccount />} />
					<Route
						path='/already-activated'
						element={<AlreadyActivatedAccount />}
					/>
					<Route
						path='/after-activation'
						element={<AfterActivation />}
					/>
					<Route path='/templates' element={<Templates />} />
					<Route path='/not-found' element={<NotFound />} />
					<Route path='*' element={<Navigate to='/not-found' />} />
				</Routes>
				<Footer />
			</div>
		</BrowserRouter>
	)
}

export default App
