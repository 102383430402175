import { useState } from 'react'
import '../style/Home.scss'
import Hero from '../components/Hero'
import Websites from '../components/Websites'
import Pricing from '../components/Pricing'
import Team from '../components/Team'
import FAQ from '../components/FAQ'
import Hire from '../components/Hire'
import Projects from '../components/Projects'

function Home() {
	const [email, setEmail] = useState('')

	const handleChange = (e) => {
		setEmail(e.target.value)
	}

	return (
		<div className='home'>
			<Hero email={email} onChange={handleChange} />
			<Websites />
			<Pricing />
			<Team />
			<FAQ />
			<Hire email={email} onChange={handleChange} />
			<Projects />
		</div>
	)
}

export default Home
