import '../style/FAQ.scss'
import sectionCurve from '../assets/backgroundFAQ.svg'
import FAQItem from './common/FAQItem'

const FAQ = () => {
	const data = [
		{
			question: 'How long does it take to finish a project ?',
			answer: 'It depends on a lot of factors such as the type of project, the requirements from the client etc. An average project should take around 1 week to be done.',
		},
		{
			question: 'How often can I see the progress done to the project ?',
			answer: 'You can view it even in real time, one of our developers will share-screen on Discord every time he works on the project.',
		},
		{
			question:
				'Are there any extra features that can be added on my website ?',
			answer: "Yes, there are but unfortunately they cost extra money. This is not a problem because you don't need to buy any of them",
		},
		{
			question:
				"What if I will decide that I don't need the website anymore ?",
			answer: 'Depending on how much work was put into it we will refund you a part of the total amount. ex: Website is 70% done, then you will recieve a refund of 30% of the total price payed.',
		},
		{
			question: 'Do we offer website maintainance ?',
			answer: 'Yes we do, unfortunately that is an extra cost that is applied per month to keep your website clean and good looking. Maintainance also means that we keep adding updates to it.',
		},
		{
			question: 'Are there any free features ?',
			answer: 'Yes the free thing every customer gets is 1 month maintainance so that we can solve any unexpected bugs that can appear after turning the website to production mode.',
		},
		{
			question: 'Do we hire ?',
			answer: 'Yes we hire but before getting hired you have to take our web-developer classes regardless of you being an experienced developer or you are just someone that has never opened a PC.',
		},
		{
			question: 'Are there any special things for sudents ?',
			answer: "Yes there are, we give you access to our web-developer classes at the end of which you will recieve an offer of getting hired by us if you behave properly and don't miss any of them.",
		},
		{
			question: 'Why are the web-developoer classes free ?',
			answer: 'We try to give everyone access to a better future by teaching them all the things needed to get hired by us.',
		},
	]

	return (
		<div className='faq-wrapper' id='faq'>
			<img
				src={sectionCurve}
				className='section-curve'
				alt='Section curve'
			/>
			<div className='faq-content'>
				<div className='container'>
					<div className='faq'>
						<h1 className='faq-title'>FAQ</h1>
						<div className='faq-container'>
							<div className='faq-items'>
								{data.map((item, index) => (
									<div key={index} className='faq-item'>
										<FAQItem
											question={item.question}
											answer={item.answer}
										/>
									</div>
								))}
								<div className='faq-item'>
									<div
										className='faq-item-question'
										style={{ backgroundColor: '#040F21' }}
									>
										<p className='faq-item-question-text'>
											For any other questions join our
											Discord server.
										</p>
										<a
											href='https://discord.gg/cuyx4qyRya'
											className='faq-item-question-discord'
										>
											Discord
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<img
				src={sectionCurve}
				className='section-curve-bottom'
				alt='Section curve'
			/>
		</div>
	)
}

export default FAQ
