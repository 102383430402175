import axios from 'axios'
import {
	STUDENT_LIST_REQUEST,
	STUDENT_LIST_SUCCESS,
	STUDENT_LIST_FAIL,
	STUDENT_REGISTER_REQUEST,
	STUDENT_REGISTER_SUCCESS,
	STUDENT_REGISTER_FAIL,
} from '../constants/studentConstants'

export const registerStudent =
	(firstName, lastName, email, discord, age) => async (dispatch) => {
		try {
			dispatch({ type: STUDENT_REGISTER_REQUEST })

			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			}

			const { data } = await axios.post(
				'https://api.adxiom.com/api/students',
				{
					first_name: firstName,
					last_name: lastName,
					email,
					discord,
					age: parseInt(age),
				},
				config
			)

			dispatch({
				type: STUDENT_REGISTER_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: STUDENT_REGISTER_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const listStudents = () => async (dispatch) => {
	try {
		dispatch({ type: STUDENT_LIST_REQUEST })

		const { data } = await axios.get('https://api.adxiom.com/api/students')

		dispatch({
			type: STUDENT_LIST_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: STUDENT_LIST_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
