import '../style/Team.scss'
import user from '../assets/user-tie-solid.svg'
import instagram from '../assets/instagram.svg'
import linkedIn from '../assets/linkedin.svg'
import background from '../assets/background.svg'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const Team = () => {
	const data = [
		{
			img: user,
			name: 'Radu Andrei',
			position: 'CEO',
			instagramLink: 'https://www.instagram.com/adxiom/',
			linkedInLink:
				'https://www.linkedin.com/in/guramulta-radu-204a1622a/',
		},
		{
			img: user,
			name: 'Ion Gabriel',
			position: 'Marketing Manager',
		},
		{
			img: user,
			name: 'Dina Filip',
			position: 'Frontend Lead Dev',
			instagramLink: 'https://www.instagram.com/efix_filip/',
			linkedInLink: 'https://www.linkedin.com/in/filip-dina-bb4787230/',
		},
		{
			img: user,
			name: 'Teodorescu Costin',
			position: 'Backend Lead Dev',
		},
	]

	useEffect(() => {
		Aos.init({ duration: 700 })
	}, [])

	return (
		<div className='team-wrapper' id='team'>
			<div className='container'>
				<div className='team'>
					<h1 className='team-title'>The Dream Team</h1>
					<div className='team-container'>
						<img
							src={background}
							className='team-container-background1'
							alt='background'
						/>
						<img
							src={background}
							className='team-container-background2'
							alt='background'
						/>
						{data.map((item, index) => (
							<div
								data-aos='fade-up'
								data-aos-delay={(index * 100).toString()}
								key={index}
								className='team-card'
							>
								<img
									className='team-card-img'
									src={item.img}
									alt='portrait'
								/>
								<div className='team-card-text'>
									<h2 className='team-card-text-name'>
										{item.name}
									</h2>
									<p className='team-card-text-position'>
										{' '}
										{item.position}
									</p>
									<div className='team-card-text-medias'>
										<a
											href={
												item.instagramLink
													? item.instagramLink
													: 'https://instagram.com'
											}
										>
											<img
												className='team-card-text-media'
												src={instagram}
												alt='instagram'
											></img>
										</a>
										<a
											href={
												item.linkedInLink
													? item.linkedInLink
													: 'https://linkedin.com'
											}
										>
											<img
												className='team-card-text-media'
												src={linkedIn}
												alt='linked-in'
											></img>
										</a>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Team
