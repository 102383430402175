import {
	STUDENT_LIST_REQUEST,
	STUDENT_LIST_SUCCESS,
	STUDENT_LIST_FAIL,
	STUDENT_REGISTER_REQUEST,
	STUDENT_REGISTER_SUCCESS,
	STUDENT_REGISTER_FAIL,
} from '../constants/studentConstants'

export const studentListReducer = (state = { students: [] }, action) => {
	switch (action.type) {
		case STUDENT_LIST_REQUEST:
			return { loading: true, students: [] }
		case STUDENT_LIST_SUCCESS:
			return { loading: false, students: action.payload }
		case STUDENT_LIST_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const studentRegisterReducer = (state = { studentInfo: {} }, action) => {
	switch (action.type) {
		case STUDENT_REGISTER_REQUEST:
			return { loading: true }
		case STUDENT_REGISTER_SUCCESS:
			return { loading: false, studentInfo: action.payload }
		case STUDENT_REGISTER_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}
