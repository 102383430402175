import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
	studentListReducer,
	studentRegisterReducer,
} from './reducers/studentReducers'
import { newsletterSubscriptionReducer } from './reducers/newsletterReducers'
import { userLoginReducer, userRegisterReducer } from './reducers/userReducers'
import { emailSendReducer } from './reducers/emailReducers'

const reducer = combineReducers({
	studentList: studentListReducer,
	studentRegister: studentRegisterReducer,
	newsletterSubscription: newsletterSubscriptionReducer,
	userLogin: userLoginReducer,
	userRegister: userRegisterReducer,
	emailSend: emailSendReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo'))
	: null

const initialState = {
	userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware))
)

export default store
