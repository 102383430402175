import '../style/Hire.scss'
import { useState } from 'react'
import background from '../assets/background.svg'
import { useDispatch, useSelector } from 'react-redux'
import { registerStudent } from '../actions/studentActions'

const Hire = ({ email, onChange }) => {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [discord, setDiscord] = useState('')
	const [age, setAge] = useState('')

	const dispatch = useDispatch()

	const studentRegister = useSelector((state) => state.studentRegister)
	const { loading, error, studentInfo } = studentRegister

	const handleSubmit = (e) => {
		e.preventDefault()
		dispatch(registerStudent(firstName, lastName, email, discord, age))
	}

	return (
		<div className='hire-wrapper'>
			<div className='container'>
				<div className='hire'>
					<h1 className='hire-title'>
						Find your dream job with us easily
					</h1>
					<div className='hire-container'>
						<img
							src={background}
							className='hire-container-background'
							alt='background'
						/>
						<div className='hire-form-container'>
							<div className='hire-form-title-container'>
								{studentInfo && studentInfo.first_name ? (
									<>
										<h2>Welcome,</h2>
										<h2>
											{studentInfo.first_name}{' '}
											{studentInfo.last_name}
										</h2>
									</>
								) : (
									<>
										<h2>Fill the form.</h2>
										<h2>It's easy.</h2>
									</>
								)}
							</div>
							{loading && <p>Loading...</p>}
							{error && <p>{error}</p>}
							<form className='hire-form' onSubmit={handleSubmit}>
								<div className='hire-form-input-name-container'>
									<input
										type='text'
										className='hire-form-input hire-form-input-name'
										value={firstName}
										placeholder='First name'
										onChange={(e) =>
											setFirstName(e.target.value)
										}
									></input>
									<input
										type='text'
										className='hire-form-input hire-form-input-name'
										value={lastName}
										placeholder='Last name'
										onChange={(e) =>
											setLastName(e.target.value)
										}
									></input>
								</div>
								<input
									type='text'
									className='hire-form-input hire-form-input-email'
									style={{ width: '100%' }}
									value={email}
									placeholder='Email'
									onChange={(e) => onChange(e)}
								></input>
								<div className='hire-form-input-name-container'>
									<input
										type='text'
										className='hire-form-input hire-form-input-name'
										value={discord}
										placeholder='Discord'
										onChange={(e) =>
											setDiscord(e.target.value)
										}
									></input>
									<input
										type='number'
										className='hire-form-input hire-form-input-name'
										value={age}
										placeholder='Age'
										onChange={(e) => setAge(e.target.value)}
									></input>
								</div>
								<button className='hire-form-button'>
									Submit
								</button>
							</form>
						</div>
						<div className='hire-text-container'>
							<h2 className='hire-text-title'>
								Let's talk about everything.
							</h2>
							<p className='hire-text-text'>
								Instead of wasting time why wouldn’t you give it
								up in exchange to learning a useful skill ?
							</p>
							<p className='hire-text-text'>
								We take you from complete noob to a junior
								developer or even intermediate level, providing
								to you all the knowledge needed to acheve this
								step in your career.
							</p>
							<p className='hire-text-text'>
								All of these while working on real life projects
								along with the teacher that will guide your way
								into this amazing world of WEB.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Hire
