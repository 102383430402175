import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { sendEmailVerification } from '../actions/emailActions.js'
import '../style/MyAccount.scss'

const MyAccount = () => {
	const emailSend = useSelector((state) => state.emailSend)
	const {
		loading: loadingEmailSend,
		error: errorEmailSend,
		success: successEmailSend,
	} = emailSend

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		if (!userInfo) {
			navigate('/log-in')
		}
	})

	const handleSendEmailVerification = () => {
		dispatch(sendEmailVerification())
	}
	return (
		<div className='my-account-wrapper'>
			<div className='container'>
				<div className='my-account'>
					{userInfo && (
						<>
							<h1 className='my-account-title'>
								Hi, {userInfo.first_name} {userInfo.last_name}
							</h1>
							<div className='my-account-info-container'>
								<h2 className='my-account-info-label'>
									First Name:
								</h2>
								<p className='my-account-info-value'>
									{userInfo.first_name}
								</p>
							</div>

							<div className='my-account-info-container'>
								<h2 className='my-account-info-label'>
									Last Name:
								</h2>
								<p className='my-account-info-value'>
									{userInfo.last_name}
								</p>
							</div>
							<div className='my-account-info-container'>
								<h2 className='my-account-info-label'>
									Email:
								</h2>
								<p className='my-account-info-value'>
									{userInfo.email}
								</p>
							</div>
							<div className='my-account-info-container'>
								<h2 className='my-account-info-label'>
									Verified Email:
								</h2>
								<p className='my-account-info-value'>
									{userInfo.confirmed ? 'Yes' : 'No'}
								</p>
							</div>
							{!userInfo.confirmed && (
								<button
									className='my-account-email-verification'
									onClick={handleSendEmailVerification}
								>
									{successEmailSend
										? 'Email Sent'
										: loadingEmailSend
										? 'Loading...'
										: errorEmailSend
										? 'error'
										: 'Send Email Verification'}
								</button>
							)}
							{!userInfo.confirmed && (
								<p style={{ marginTop: 20 }}>
									I order for the verification to happen,
									please log out and log in again.
								</p>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default MyAccount
